
.legal{
  line-height: 1.25;
  @extend .uk-text-small;
}

.uk-dropzone{
  padding: 25px;
  color: $domestiko-soil;
  background: $domestiko-light;
  border: dashed 2px $domestiko-gray;
  text-align: center;
  cursor: pointer;
  span.icon{
    font-size: 1.5em;
    position: relative;
    top: 5px;
  }
}

.uk-button-bookmark{
  @extend .uk-button;
  @extend .uk-button-text;
  span{
    position: relative;
    top: 2px; right: 3px;
  }
}